/**
 * Generates a random alphanumeric string of a specified length.
 * Used for generating a unique name identifier for the alert.
 * @param {number} length - The length of the generated string
 * @returns {string} The generated random alphanumeric string.
 */
export const genRandomId = (length: number = 10): string => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let name = '';

  for (let i = 0; i < length; i++) {
    name += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return name;
};
